import { useState } from 'react';
import { Card, Button, Offcanvas, Modal } from 'react-bootstrap';

export function ExecutedOrderBox({ order }) {
    return <>
        <Card className="border-0 p-0 mb-2">
            <Card.Body className="px-3 py-2">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="font-m fw-semibold">{order.trading_symbol}</span><br />
                        <span className={`${(order.type === "buy") ? "label-green" : "label-red"} font-s me-1 mb-1`}>QTY:{order.type === "sell" && "-"}{order.qty}</span> <span className="label-blue font-s mb-1">{order.order_type}</span> <br />
                        <span className="label-grey font-s text-black">{order.exchange}</span>
                    </div>
                    <div className="text-end">
                        <span className="font-s font-dark-grey">{order.added_on}</span><br />
                        <span className="font-m fw-semibold mb-1">{order.price}</span><br />
                        <span className={`${(order.status === "complete") ? "label-green" : "label-red"} font-s`}>{order.status}</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </>
}

export function PendingOrderBox({ order, openModalHandler }) {
    return <>
        <Card className="border-0 p-0 mb-2" onClick={() => { openModalHandler(order) }}>
            <Card.Body className="px-3 py-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="font-m fw-semibold">{order.trading_symbol}</span><br />
                        <span className="me-2 label-blue font-s mb-8">BUY</span> <span className="label-grey font-s text-black mb-8">{order.exchange}</span> <br />
                        <span className="label-yellow font-s">{order.order_type}</span> <span className={`ms-2 font-s ${(order.type === "buy") ? "font-blue" : "font-red"}`}>QTY:{(order.type === "sell") && "-"}{order.qty}</span>
                    </div>
                    <div className="text-end">
                        <span className="font-m fw-semibold">{order.price}</span><br />
                        <span className="font-s font-dark-grey mb-8 d-inline-block">{order.added_on}</span><br />
                        <span className="label-yellow font-s"><i className="font-s fa-regular fa-clock"></i> PENDING</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </>
}

export function TradeBox({ trade }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const net = (parseFloat(trade.profit_and_loss) - parseFloat(trade.brokerage)).toFixed(2);
    return <>
        <Card className="border-0 p-0 mb-2" onClick={handleShow}>
            <Card.Body className="px-3 py-2">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="font-m fw-semibold">{trade.trading_symbol}</span><br />
                        <span className="font-s font-dark-grey">Used Margin:{trade.used_margin}</span><br />
                        <span className="font-s font-dark-grey">P&L: </span><span className={`${(trade.profit_and_loss > 0) ? "font-green" : "font-red"} fw-bold font-s`}>{trade.profit_and_loss}</span>
                    </div>
                    <div className="text-end">
                        <span className="font-m fw-semibold"><i class="fa-solid fa-angle-right"></i></span><br />
                        <span className={`font-s font-dark-grey ${(trade.type === "buy") ? "label-green" : "label-red"}`}>QTY: {trade.qty}</span><br />
                        <span className="font-s font-dark-grey">Exit at:{trade.added_on}</span>
                    </div>
                </div>
            </Card.Body>
        </Card>

        <Offcanvas className="orders-trades-popup" placement="bottom" show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
            </Offcanvas.Header>
            <Offcanvas.Body className="p-0 bg-grey">
                <div className="p-3">
                    <div className="font-m fw-semibold mb-16">{trade.trading_symbol}</div>
                    <ul className="popup-lists">
                        <li>
                            <span className="font-m">QTY</span>
                            <span className="font-m text-black">{trade.qty}</span>
                        </li>
                        <li>
                            <span className="font-m">Buy Price :</span>
                            <span className="font-m text-black">{trade.buy_price}</span>
                        </li>
                        <li>
                            <span className="font-m">Sell Price :</span>
                            <span className="font-m text-black">{trade.sell_price}</span>
                        </li>
                        <li>
                            <span className="font-m">Brokerage :</span>
                            <span className="font-m text-black">{trade.brokerage}</span>
                        </li>
                        <li>
                            <span className="font-m">UsedMargin :</span>
                            <span className="font-m text-black">{trade.used_margin}</span>
                        </li>
                        <li>
                            <span className="font-m">P&L :</span>
                            <span className={`font-m ${(trade.profit_and_loss > 0) ? "font-green" : "font-red"}`}>{trade.profit_and_loss}</span>
                        </li>
                        <li>
                            <span className="font-m">Net :</span>
                            <span className={`font-m ${(net > 0) ? "font-green" : "font-red"}`}>{net}</span>
                        </li>
                    </ul>
                </div>

                <Button className="orders-popup-btn">
                    Exit at : {trade.added_on}
                </Button>
            </Offcanvas.Body>

        </Offcanvas>
    </>
}


export function ModalBox({ show, onHide, handleYes }) {
    return <>
        <Modal centered className="theme-popup" show={show} onHide={onHide}>
            {/* <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header> */}
            <Modal.Body className="p-4 pb-0 text-center mb-3">
                <img src="images/explanation-mark.svg" alt="explanation-mark" className="pb-20" />
                <div className="font-ml fw-semibold mb-2">Are you sure ?</div>
                <p className="font-m font-dark-grey mb-0">You won't to cancel this order</p>
            </Modal.Body>
            <Modal.Footer className="border-0 pb-4 pt-0 d-flex justify-content-center">
                <Button className="yes-btn me-3" onClick={handleYes}>
                    Yes
                </Button>
                <Button className="cancle-btn" onClick={onHide}>
                    Cancle
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}