import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import api from "../../../helper/api_helper";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import { SEND_OTP_SIGNUP_URL } from '../../../helper/url_helper';
import { useSelector, useDispatch } from "react-redux";
import { setData as setSignupData } from '../../../store/signup/action';

export default function Signup() {
    const dispatch = useDispatch();
    const history = useHistory();
    const signupState = useSelector((state) => state.signup);
    const [showPasswords, setShowPasswords] = useState([false, false]);
    const [data, setData] = useState({
        "firstname": "",
        "lastname": "",
        "email": "",
        "contact": "",
        "referer_id": "",
        "password": "",
        "confirm_password": ""
    });

    function togglePassword(index) {
        let showPasswordsTemp = JSON.parse(JSON.stringify(showPasswords));
        showPasswordsTemp[index] = (!showPasswordsTemp[index]);
        setShowPasswords(showPasswordsTemp);
    }

    const changeDataHandler = (value, field, validationType = "any") => {
        // Perform validation based on the validationType
        let updatedValue = value;

        if (validationType === 'alpha_only') {
            // Allow only alphabetic characters
            updatedValue = value.replace(/[^a-zA-Z]/g, '');
        } else if (validationType === 'number_only') {
            // Allow only numeric characters
            updatedValue = value.replace(/\D/g, '');
        }

        // Update the data state
        setData(prevData => ({
            ...prevData,
            [field]: updatedValue
        }));
    };
    // submit and move on next page
    async function submit() {
        try {

            if (data["password"] !== data["confirm_password"]) {
                toast.error("Confirm password not matched");
                return;
            }
            let d = {
                "firstname": data["firstname"],
                "lastname": data["lastname"],
                "email": data["email"],
                "contact_no": data["contact"],
                "password": data["password"],
            }
            if (data["referer_id"]) {
                d["referrer_id"] = data["referrer_id"];
            }
            let res = await api.post(SEND_OTP_SIGNUP_URL, d);
            if (res.data.status === "SUCCESS") {
                dispatch(setSignupData(d));
                //    move on next page
                history.push("verification");
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    return <>
        <div className="screen">
            <div className="signup-wrapper bg-white">
                <div className="d-flex justify-content-center flex-column">
                    <p className="big-title">Sign Up</p>
                    <p className="text mb-30">Unlock Your Financial Potential</p>
                    <div className="form-wrapper">
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className="custom-label">First Name</Form.Label>
                                <Form.Control className="custom-form-control grey-bg" size="lg" type="text" placeholder="John" value={data["firstname"]} onChange={(e) => { changeDataHandler(e.target.value, "firstname", "alpha_only") }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Label className="custom-label">Last Name</Form.Label>
                                <Form.Control className="custom-form-control grey-bg" size="lg" type="text" placeholder="John" value={data["lastname"]} onChange={(e) => { changeDataHandler(e.target.value, "lastname", "alpha_only") }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                <Form.Label className="custom-label">Email</Form.Label>
                                <Form.Control className="custom-form-control grey-bg" size="lg" type="email" placeholder="Name@example.com" value={data["email"]} onChange={(e) => { changeDataHandler(e.target.value, "email") }} />
                            </Form.Group>
                            {/*<Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                <Form.Label className="custom-label">Refre Id (Optional)</Form.Label>
                                <Form.Control className="custom-form-control grey-bg" size="lg" type="text" placeholder="54545968599gffd" value={data["referer_id"]} onChange={(e) => { changeDataHandler(e.target.value, "referer_id", "number_only") }} />
                            </Form.Group>*/}
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label className="custom-label">Contact no.</Form.Label>
                                <Form.Control className="custom-form-control grey-bg" size="lg" type="text" placeholder="99589465323" value={data["contact"]} onChange={(e) => { changeDataHandler(e.target.value, "contact", "number_only") }} />
                            </Form.Group>
                            <Form.Group className="mb-2" controlId="formGroupPassword">
                                <Form.Label className="custom-label">Password</Form.Label>
                                <Form.Control className="custom-form-control grey-bg eye-icons" size="lg" type={showPasswords[0] ? "text" : "password"} placeholder="***************" value={data["password"]} onChange={(e) => { changeDataHandler(e.target.value, "password") }} />
                                <div class="input-icon" onClick={() => { togglePassword(0) }}><img class="right-icon" src="images/eye.svg" /></div>
                            </Form.Group>
                            <Form.Group className="mb-2" controlId="formGroupPassword">
                                <Form.Label className="custom-label">Confirm Password</Form.Label>
                                <Form.Control className="custom-form-control grey-bg eye-icons" size="lg" type={showPasswords[1] ? "text" : "password"} placeholder="***************" value={data["confirm_password"]} onChange={(e) => { changeDataHandler(e.target.value, "confirm_password") }} />
                                <div class="input-icon" onClick={() => { togglePassword(1) }}><img class="right-icon" src="images/eye.svg" /></div>
                            </Form.Group>
                            {/* <div className="d-flex justify-content-end">
                                <a className="custom-link">Forgot your password?</a>
                            </div> */}
                            <div className="mt-30">
                                <Button className="signin-btn w-100" onClick={submit}>Create Account</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>

    </>
}