import React, { useState, useEffect, useRef } from 'react';
import { WatchlistBox, ToastMessage } from '../../../components/watchlist/smallComponents.jsx';
import { Card, Tab, Nav, Form, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { fetchWatchlist } from "../../../store/watchlist/action";
import { changeStatusSheetWatchlist, updateInstrumentSheetWatchlist } from '../../../store/bottomSheetWatchlist/action.jsx';
import { Footer } from '../../../components/common/common.jsx';
// import BottomSheet from '../../../components/common/BottomSheet.jsx';
import { BottomSheetWatchlist } from './BottomSheetWatchlist.jsx';
import { IndexBox } from '../../../components/common/common.jsx';
import { useHistory } from 'react-router-dom';
import { EmptyPageImage } from '../../../components/common/common.jsx';

export default function Watchlist() {
    const history = useHistory();
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.login);
    const watchlistState = useSelector((state) => state.watchlist);
    const socketState = useSelector((state) => state.socket);
    const [watchlist, setWatchlist] = useState({
        "NFO": {},
        "MCX": {},
        "CDS": {},
        "CRYPTO": {},
        "FX": {},
        "US": {}
    })
    const [watchlistIndex, setWatchlistIndex] = useState({
        "NFO": [],
        "MCX": [],
        "CDS": [],
        "CRYPTO": [],
        "FX": [],
        "US": []
    })
    const watchlistRef = useRef(null);
    watchlistRef.current = watchlist

    const markets = ["NFO", "MCX", "CRYPTO", "FX", "US"]
    useEffect(() => {
        dispatch(fetchWatchlist(loginState.jwttoken))
    }, [])

    useEffect(() => {
        let temp = {
            "NFO": {},
            "MCX": {},
            "CDS": {},
            "CRYPTO": {},
            "FX": {},
            "US": {}
        };
        let tempIndex = {
            "NFO": [],
            "MCX": [],
            "CDS": [],
            "CRYPTO": [],
            "FX": [],
            "US": []
        };

        markets.forEach((exchange) => {
            watchlistState.watchlist[exchange].forEach((instrument) => {
                // store in local watchlist for fast updating structure
                temp[exchange][instrument.token] = instrument;
                // set index
                tempIndex[exchange].push(instrument.token);
                // subscribe price
                socketState.priceSocket.emit("tokenData", instrument.token)
                // when price come
                socketState.priceSocket.on("room" + instrument.token, (instrument) => {
                    // let watchlist = watchlistRef.current
                    // let temp = { ...watchlist[instrument.exchange] };
                    // temp[instrument.token] = instrument;
                    // console.log(watchlist[instrument.exchange])
                    // setWatchlist({ ...watchlist, [instrument.exchange]: { ...watchlist[instrument.exchange], [instrument.token]: instrument } })
                    setWatchlist(prevState => ({
                        ...prevState,
                        [instrument.exchange]: {
                            ...prevState[instrument.exchange],
                            [instrument.token]: instrument
                        }
                    }));
                })
            })
        });
        setWatchlistIndex(tempIndex)
        setWatchlist(temp)

        return () => {
            // Unsubscribe from socket events
            markets.forEach((exchange) => {
                watchlistState.watchlist[exchange].forEach((instrument) => {
                    socketState.priceSocket.off("room" + instrument.token);
                });
            });
        };
    }, [watchlistState.watchlist])


    function sheetChangingHandler(instrument) {
        dispatch(updateInstrumentSheetWatchlist(instrument));
        dispatch(changeStatusSheetWatchlist(true));
    }



    return <>
        <div className="screen">
            <BottomSheetWatchlist />
            <div className="main-content bg-light">
                {/* first section */}
                <div>
                    <Card className="border-0 bg-transparent p-0 mb-3">
                        <Card.Body className="d-flex justify-content-between align-items-center p-0">
                            <div className="col-6">
                                <span className="fw-semibold">Watchlist</span>
                            </div>
                            <div className="col-5">
                                <Form.Group controlId="formGroupPassword" onClick={() => { history.push("search-bar") }}>
                                    <Form.Control readOnly style={{ borderRadius: "50px" }} className="search-form-control" type="text" placeholder="Search" />
                                    <div className="search-icon"><img className="right-icon" src="images/search.svg" /></div>
                                </Form.Group>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                {/* second section indexboxes */}
                <IndexBox />
                {/* main content */}
                <div>
                    <Tab.Container id="tabs-example" defaultActiveKey="NFO">
                        {/* section three markets and exchanges tabs */}
                        <Nav className="d-flex justify-content-between watchlist-tab" variant="tabs">
                            {markets.map((value, index) => {
                                return <>
                                    <Nav.Item key={index}>
                                        <Nav.Link eventKey={value}>{value.toUpperCase()}</Nav.Link>
                                    </Nav.Item>
                                </>
                            })}
                        </Nav>
                        {/*  */}
                        <Tab.Content className="watch-list-box-pannel">
                            {/* watchlist */}

                            {markets.map((value) => {
                                if ((Object.keys(watchlist[value]).length === 0)) {
                                    return <>
                                        <Tab.Pane eventKey={value} key={value}>
                                            <EmptyPageImage />
                                        </Tab.Pane>

                                    </>
                                }
                                return <>
                                    <Tab.Pane eventKey={value} key={value}>
                                        {watchlistIndex[value].map((key) => {
                                            let instrument = watchlist[value][key]
                                            return <>
                                                <WatchlistBox key={instrument.token} instrument={instrument} sheetChangingHandler={sheetChangingHandler} />
                                            </>
                                        })}

                                    </Tab.Pane>
                                </>
                            })}

                            {/* <Tab.Pane className="slide-in" eventKey="forex" transition={false}>
                                <WatchlistBox />
                            </Tab.Pane>
                            <Tab.Pane className="slide-in" transition={false} eventKey="fno">
                                <WatchlistBox />
                            </Tab.Pane>
                            <Tab.Pane className="slide-in" transition={false} eventKey="mcx">
                                <WatchlistBox />
                            </Tab.Pane>
                            <Tab.Pane className="slide-in" transition={false} eventKey="us">
                                <WatchlistBox />
                            </Tab.Pane> */}
                        </Tab.Content >
                    </Tab.Container>
                </div>



            </div>
            {/* <ToastMessage /> */}

            {/* footer */}
            <Footer />
            {/* <div className="footer">
                <Tab.Container className="slide-in" transition={false} id="tabs-example" defaultActiveKey="watchlist">
                    <Nav className="d-flex justify-content-between footer-tabs" variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="market"><div className="mb-2"><img src="images/market.svg" alt="market" /></div>Market</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="watchlist"><div className="mb-2"><img src="images/watchlist.svg" alt="watchlist" /></div>Watchlist</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="portfolio"><div className="mb-2"><img src="images/portfolio.svg" alt="portfolio" /></div>Portfolio</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="order"><div className="mb-2"><img src="images/order.svg" alt="order" /></div>Order</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="account"><div className="mb-2"><img src="images/account.svg" alt="account" /></div>Account</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="market">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="watchlist">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="portfolio">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="order">
                            <div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="account">
                            <div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div> */}
        </div>
        {/* section one */}
        {/* <section className='card d-flex justify-content-between'>
            <div>
                <span className='font-m'>Watchlist Crypto</span>
            </div>
            <div>
                <div className='bg-white px-20px py-5px font-dark-grey font-s btn-cy'>Search</div>
            </div>
        </section> */}
        {/* section two */}
        {/* <section className='d-flex justify-content-between mt-10px'>
                <IndexBox className="mr-20px flex-grow-1" />
                <IndexBox className="flex-grow-1" />
            </section> */}

        {/* section three */}
        {/* <section className='mt-10px'>
            <div className='card d-flex justify-content-between py-10px'>
                <RoundedButton classes="bg-blue font-white flex-grow-1">FNO</RoundedButton>
                <RoundedButton classes="bg-light flex-grow-1">MCX</RoundedButton>
                <RoundedButton classes="bg-light flex-grow-1">FX</RoundedButton>
                <RoundedButton classes="bg-light flex-grow-1">CRYPTO</RoundedButton>
                <RoundedButton classes="bg-light flex-grow-1">US</RoundedButton>
            </div>
            <div className=''>
                <WatchlistBox />
                <div className='mt-10px'></div>
                <WatchlistBox />
                <div className='mt-10px'></div>
                <WatchlistBox />
                <div className='mt-10px'></div>
                <WatchlistBox />
            </div>
        </section>
    </div > */}
    </>
}