import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { login } from "../../store/login/action";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';

export default function Login() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState({ "username": "", "password": "" })
    const [passwordToggle, setPasswordToggle] = useState(true)
    const loginHandler = () => {
        if (!(data.username && data.password)) {
            toast.error("ALL FIELDS REQUIRED");
            return
        }
        dispatch(login(data));
    };

    return <>
        <div className="screen">
            <div className="main-content bg-white">
                <div className="d-flex justify-content-center flex-column h-100">
                    <p className="big-title">Sign In</p>
                    <p className="text mb-30">Hello, valued Trader!</p>
                    <div className="form-wrapper">
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className="custom-label">Username</Form.Label>
                                <Form.Control className="custom-form-control grey-bg" size="lg" type="text" placeholder="BDU445" value={data.username}
                                    onChange={(e) => {
                                        setData({ ...data, username: e.target.value });
                                    }} />
                            </Form.Group>
                            <Form.Group className="mb-2" controlId="formGroupPassword">
                                <Form.Label className="custom-label">Password</Form.Label>
                                <Form.Control className="custom-form-control eye-icons grey-bg" size="lg" type={passwordToggle ? "password" : "text"} placeholder="***************"
                                    value={data.password}
                                    onChange={(e) => {
                                        setData({ ...data, password: e.target.value });
                                    }} />
                                <div className="input-icon"><img className="right-icon" src="images/eye.svg" onClick={() => { setPasswordToggle(!passwordToggle) }} /></div>
                            </Form.Group>
                            <div className="d-flex justify-content-end" onClick={() => { history.push("/forgot-password") }}>
                                <a className="custom-link">Forgot your password?</a>
                            </div>
                            <div className="mt-30">
                                <Button className="signin-btn w-100" onClick={loginHandler}>Sign In</Button>
                            </div>
                            <div className="d-flex justify-content-center mt-30" onClick={() => { history.push("/signup") }}>
                                <a className="normal-link">Create new account</a>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>

    </>
}